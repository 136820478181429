import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Container from "../../components/Container"
import KitchenGalleryComponent from "../../components/gallery/KitchenGalleryComponent"

const KitchenGalleryPage = () => (
  <Layout>
    <SEO title="Gallery" />
    <Container
      noTitle
      style={{
        background: `#52575a`,
        color: `white`,
      }}
      whitetitle
    >
      <KitchenGalleryComponent />
    </Container>
  </Layout>
)

export default KitchenGalleryPage
